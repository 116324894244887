<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex inputs-wrap justify-between overflow-y-auto">
      <ul
        class="w-2/6 overflow-y-auto p-8 bg-gray-100 text-black"
        aria-label="notes"
      >
        <li>
          the document name is how the document will be known to the rest of the
          application, use a significant name.
        </li>
        <li>choose the category of that document belongs.</li>
        <li>describes with detail what the document is used for.</li>
        <li>
          fields input is where you set the data fields that belongs to this document.
        </li>
      </ul>
      <div class="w-4/6 px-4 mx-4 overflow-y-auto">
        <div class="form-row">
          <h1 class="my-4 text-blue-600 font-bold text-xl">
            {{ partner }}
          </h1>
        </div>
        <div class="form-row">
          <div class="form-col">
            <label for="document_name">document name</label>
            <input
              name="document_name"
              type="text"
              class="form-input"
              v-model="document.name"
            />
            <span v-if="hasError('document.name')" class="form-error">
              {{ showError("document.name") }}
            </span>
          </div>
          <div class="form-col">
            <label for="document_label">document label</label>
            <input
              name="document_label"
              type="text"
              class="form-input"
              v-model="document.label"
            />
            <span v-if="hasError('document.label')" class="form-error">
              {{ showError("document.label") }}
            </span>
          </div>
          <div class="form-col">
            <label for="category">document category</label>
            <dropdown
              :options="categories"
              :placeholder="'select category'"
              v-model="document.category"
              :multiple="true"
            ></dropdown>
            <span v-if="hasError('document.category')" class="form-error">
              {{ showError("document.category") }}
            </span>
          </div>
        </div>
        <div class="form-row">
          <div class="form-col">
            <label for="description">description</label>
            <textarea
              rows="3"
              type="text"
              class="form-input"
              name="description"
              v-model="document.description"
            />
            <span v-if="hasError('document.description')" class="form-error">
              {{ showError("document.description") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <modal-footer :footer="footer" :primary="save" :tertiary="cancel" />
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Loader from "@/components/ui/Loader";
import Dropdown from "@/components/ui/Dropdown";
import NotifyMixin from "@/mixins/NotifyMixin";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddNewPartnerDocument",
  components: { Loader, Dropdown, ModalFooter },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  data() {
    return {
      loading: false,
      document: {
        name: "",
        category: null,
        description: "",
        label: ''
      },
      categories: [],
      footer: {
        tertiaryButton: "cancel",
        primaryButton: "save",
      },
    };
  },
  computed: {
    ...mapGetters({
      getPartner: 'partner_documents/getPartner'
    }),
  },
  methods: {
    ...mapActions({
      setPartnerDocs: 'leases/setPartnerDocs',
      setDataFields: 'leases/setDataFields',
      setQuextFields: 'leases/setQuextFields'
    }),
    async addCategory (newCategory) {
      if (newCategory) {
        this.loading = true;
        await this.$leasingDataProvider.create('document_category', {
          data: {
            document_category_name: newCategory
          }
        })
        .then(async (res) => {
          if (res.status === 201) {
            this.document.category = newCategory;
            await this.fetchDocumentCategories();
            this.notifySuccess("the document was created successfully");
          }
        })
        .catch(() => this.notifyError('Something went wrong, please try again later.'))
        .finally(() => this.loading = false);
      }
    },
    fetchDocumentCategories() {
      this.$leasingDataProvider.getList('document_category')
        .then((res) => {
          this.categories = res.data.map(category => {
            return {
              key: category.document_category_name,
              value: category.document_category_name
            }
          });
        })
        .catch(() => this.notifyError('Something went wrong, please try again later.'))
        .finally(() => this.loading = false);
    },
    save() {
      if (this.validator.passes()) {   
        
        this.$leasingDataProvider.create('partner_document', {
          data: {
            partner_id: this.getPartner,
            document_current_version: "1",
            document_category_name: this.document.category.name,
            document_name: this.document.name,
            document_label: this.document.label,
            description: this.document.description,
          }
        })
        .then((res) => {
          if (res.status === 201) {
            this.notifySuccess("the document was created successfully");
            this.cancel();
          }
        })
        .catch(() => this.notifyError('Something went wrong, please try again later.'))
        .finally(() => this.loading = false);
      }
    },
    cancel() {
      this.$router.push({ name: "manage-partner-documents.index" });
    },
  },
  validations: {
    "document.name": "required",
    "document.label": "required",
    "document.category": "required",
    "document.description": "required"
  },
  created() {
    this.initValidator();
    this.fetchDocumentCategories();
  },
};
</script>
<style scoped>
ul:before {
  content: attr(aria-label);
  font-size: 1rem;
  font-weight: bold;
  color: black;
}
ul > li {
  @apply py-3 font-light text-black;
}
.isDisabled {
  @apply text-gray-400;
}
.inputs-wrap {
  @apply pb-2;
  height: calc(72vh - var(--menuHeight));
  overflow-y: auto;
}
</style>
